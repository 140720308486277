<template>
  <div>
    <h1 class="text-danger">Unexpected error occurred.</h1>
    <p class="text-white">
      Please contact PIXCARDIO Administrators to report this incident.
    </p>
    <p class="text-white">
      Try again? <b-button variant="success" to="/login">Login</b-button>
    </p>
  </div>
</template>
<script>
export default {
  mounted () {
    this.loading = false
  }
}
</script>
